import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import axios from "axios"


function ContactPage() {
  
  function onInputAction(evnt) {
    let isValid = true;
    if(evnt.currentTarget.value.trim().length > 0) {
      if ( evnt.currentTarget.id === 'name' || evnt.currentTarget.id === 'message' || isValidEmail(evnt.currentTarget.value.trim())) {
        evnt.currentTarget.classList.remove('border-red-900');
      } else {
        isValid = false;
      }
    } else {
      isValid = false;
    }
    if (!isValid) {
      evnt.currentTarget.classList.add('border-red-900');
    }
    if (evnt.currentTarget.id === 'name') {
        let nameHelpFld = document.querySelector('#nameFieldHelp');
        if (isValid) {
            nameHelpFld.classList.remove('visible');
            nameHelpFld.classList.add('invisible');
        } else {
            nameHelpFld.classList.remove('invisible');
            nameHelpFld.classList.add('visible');
        }
    }
    if (evnt.currentTarget.id === 'email') {
        let emailHelpFld = document.querySelector('#emailFieldHelp');
        if (isValid) {
            emailHelpFld.classList.remove('visible');
            emailHelpFld.classList.add('invisible');
        } else {
            emailHelpFld.classList.remove('invisible');
            emailHelpFld.classList.add('visible');
        }
    }
  }
  
  function resetForm() {
    const nameFld = document.querySelector('#name');
    const emailFld = document.querySelector('#email');
    const messageFld = document.querySelector('#message');
    const nameHelpFld = document.querySelector('#nameFieldHelp');
    const emailHelpFld = document.querySelector('#emailFieldHelp');
    nameFld.value = '';
    emailFld.value = '';
    messageFld.value = '';
    nameFld.classList.remove('border-red-900');
    nameHelpFld.classList.add('invisible');
    emailFld.classList.remove('border-red-900');
    emailHelpFld.classList.add('invisible');
    messageFld.classList.remove('border-red-900');
  }

  function isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function contactUsAction() {
    const nameFld = document.querySelector('#name');
    const emailFld = document.querySelector('#email');
    const messageFld = document.querySelector('#message');
    const nameHelpFld = document.querySelector('#nameFieldHelp');
    const emailHelpFld = document.querySelector('#emailFieldHelp');
    const name = nameFld.value;
    const email = emailFld.value;
    const message = messageFld.value;
    let isFormValid = true;
    if (name.trim().length === 0) {
      nameFld.classList.remove('border-red-900');
      nameFld.classList.add('border-red-900');
      nameHelpFld.classList.remove('invisible')
      nameHelpFld.classList.add('visible');
      isFormValid = false;
    } 
    if (email.trim().length === 0 || !isValidEmail(email.trim())) { 
      emailFld.classList.remove('border-red-900');
      emailFld.classList.toggle('border-red-900');
      emailHelpFld.classList.remove('invisible')
      emailHelpFld.classList.add('visible');
      isFormValid = false;
    }
    if (message.trim().length === 0) { 
      messageFld.classList.remove('border-red-900');
      messageFld.classList.add('border-red-900');
      isFormValid = false;
    }
    if (isFormValid) {
      axios({
        method: 'post',
        url: 'https://0rvb4mq3be.execute-api.us-east-1.amazonaws.com/Production/contactus',
        data: {
          name: name,
          email: email,
          message: message
        }
      })
      .then(() => {
        resetForm();
        const messageDiv = document.querySelector('#successMessage');
        messageDiv.classList.toggle('hidden');
        setTimeout(() => {messageDiv.classList.toggle('hidden');}, 5000);
      }, (error) => {
        console.log(error);
      });
    }
  }
  return (
    <Layout>
      <SEO
        keywords={[`Website performance Optimization`, `Website development`, `Custom charts`]}
        title="Contact"
      />
      <section className="mx-auto md:w-1/2 mb-2 pr-4 pl-4">
          <p className="mt-8 text-2xl font-light text-gray-700">
            Please contact us for your website performance/ website development needs.
          </p>
          <p className="mb-8 text-2xl font-light text-gray-700">
            Request for a free, no obligation performance audit of your website.
          </p>

          <div className="mb-4 text-gray-700">
            <label
              className="block mb-2 text-xs font-bold uppercase"
              htmlFor="name"
            >
              Name
            </label>

            <input
              className="w-full form-input border-gray-500"
              id="name"
              name="name"
              placeholder="Enter Name"
              type="text"
              required
              onInput={onInputAction}
            />
            <span className="invisible text-xs text-red-700" id="nameFieldHelp">Enter Name.</span>
          </div>

          <div className="mb-4 text-gray-700">
            <label
              className="block mb-2 text-xs font-bold uppercase"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full form-input border-gray-500"
              id="email"
              name="email"
              placeholder="Enter Email"
              type="text"
              required
              onInput={onInputAction}
            />
            <span className="invisible text-xs text-red-700" id="emailFieldHelp">Enter Valid Email.</span>
          </div>

          
          <label
            className="block mb-2 text-xs font-bold uppercase"
            htmlFor="message" required
          >
            Message
          </label>

          <textarea
            className="w-full mb-2 form-textarea border-gray-500"
            id="message"
            name="message"
            placeholder="Enter your message"
            rows="8"
            onInput={onInputAction}
          />
          <button onClick={contactUsAction} className="bg-gray-700 text-white font-semibold rounded-full my-6 py-4 px-8 shadow-2xl focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            Submit
          </button>
          <div id="successMessage" className="hidden text-white px-6 py-4 border-0 rounded relative mb-1 bg-green-500">
            <span className="inline-block align-middle mr-8">
              Email sent Successfully.
            </span>
          </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
